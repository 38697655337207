import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"

export const getPropAddr = ( address, postcode ) => {
  
  //return address?.address1?.toLowerCase()+', '+address?.address2?.toLowerCase()+', '+address?.address3?.toLowerCase()+', '+address?.address4?.toLowerCase()+', '+address?.address5?.toLowerCase()+', '+address?.postcode;
  //return address?.replace(', '+postcode,'').replace(', E17','').toLowerCase() +', '+postcode;
  return address;
  
}

export const getPriceStr = ( price ) => {
  let priceStr = '';
  if (price.min && price.max) {
      priceStr = " between " + `£` + addCommas(price.min) + " and " + `£` + addCommas(price.max);
  } else if (price.max) {
      priceStr = " under " + `£` + addCommas(price.max);
  } else if (price.min) {
      priceStr = " over " + `£` + addCommas(price.min);
  }
  return priceStr;
}

export const getSizeStr = ( size ) => {
  let sizeStr = '';
  if (size.min && size.max) {
    sizeStr = " with size from " + addCommas(size.min) + " sqft to " + addCommas(size.max) + " sqft";
  } else if (size.max) {
    sizeStr = " with size below " + addCommas(size.max) + " sqft";
  } else if (size.min) {
    sizeStr = " with size above " + addCommas(size.min) + " sqft";
  }
  return sizeStr;
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split('.');
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  residential_sales: " for sale in ",
  residential_lettings: " to rent in ",
  commercial_sales: " for sale in ",
  commercial_lettings: " to rent in ",
  auction_commercial_sales: " for sale in ",
  auction_residential_sales: " for sale in ",
}

export const ACTION_DESC_TYPES = {
  residential_sales: " for sale ",
  residential_lettings: " to rent ",
  commercial_sales: " for sale ",
  commercial_lettings: " to rent ",
  auction_commercial_sales: " for sale ",
  auction_residential_sales: " for sale ",
}

export const pTypeBase = {
  residential_sales: "/residential-property/for-sale/",
  residential_lettings: "/residential-property/to-rent/",
  commercial_sales: "/commercial-property/for-sale/",
  commercial_lettings: "/commercial-property/to-rent/",
  auction_commercial_sales: "/auction-commercial-property/for-sale/",
  auction_residential_sales: "/auction-residential-property/for-sale/",
  auction_residential_sales: "/auction-property/for-sale/",
}

export const propertyTypesH1 = {
  "flat": "Flats",
  "house": "Houses",
  "office": "Offices",
  "retail": "Retail",
  "leisure-hospitality": "Leisures & Hospitalities",
  "industrial": "Industrials & Logistics",
  "studio": "Studios & Workshops",
  "studio-gallery": "Studios & Gallery",
  "medical": "Medicals",
  "educational": "Educationals",
  "land": "Lands & Open Storages",
  "other": "Others",
  "investment": "Investments",
  "land-development": "Lands & Developments"
}

export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location, pcategorytype, pcategorytype2) => {
  //console.log("$$$$$$", pcategorytype, pType);
  var replace_url = pcategorytype+"_"+pType;

  if (location) {
      var query = '';
      let pathUri_main = location.split(pTypeBase[replace_url].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var minsizeVal = ''
      var maxsizeVal = ''
      var typeVal = ''
      var mustIncludeVal = ''

      //console.log("$$$$$---url---####--", location+"----"+pathUri_main+"----"+pTypeBase+"-----"+pType+"----"+pcategorytype);

     // console.log("price__ 0",location, pType, pTypeBase[pType], pcategorytype, pathUri_main);
      
      //console.log("pathUri_main", pathUri_main, replace_url)

      var path_main = pathUri_main[1] ? pathUri_main[1] : pathUri_main[0];

      if( path_main ) { // following could be regexp
        let pathUri = path_main.split('/');
        
        //console.log("pathUri", pathUri, pathUri.length);

        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          // for-sale
          if ( pathUri[vi].indexOf("for-sale") >= 0 ) {
            // its area
            pType = 'sales';
          }
          if ( pathUri[vi].indexOf("to-rent") >= 0 ) {
            // its area
            pType = 'lettings';
          }
          if ( pathUri[vi].indexOf("in-") == 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replace("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }

          if(pcategorytype === "residential"){
            if ( pathUri[vi].indexOf("studio") >= 0 ) {
              // its studio bedroom
              bedVal = 0
            }
          }
          

          //console.log("price__", pathUri[vi].indexOf("for-sale"));

          if ( pathUri[vi].indexOf("between-") >= 0 || 
               pathUri[vi].indexOf("over-") >= 0 || 
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }

          // Size
          if (pathUri[vi].indexOf("size-") >= 0) {
            let sizeFilt1 = pathUri[vi].split("above-")
            if (sizeFilt1[1]) {
              minsizeVal = sizeFilt1[1]
            }
            let sizeFilt2 = pathUri[vi].split("below-")
            if (sizeFilt2[1]) {
              maxsizeVal = sizeFilt2[1]
            }
            let sizeFilt3 = pathUri[vi].split("-from-")
            if (sizeFilt3[1]) {
            let sizeFilt4 = sizeFilt3[1].split("-to-")
              minsizeVal = sizeFilt4[0]
              maxsizeVal = sizeFilt4[1]
            }
          }
          // Size

          // Must Include
          if (pathUri[vi].indexOf("with-") >= 0) {
            var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
            mustIncludeVal = mustIncludeArrfilt.split("-and-")
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            //typeVal = priceFilt5[1].replace(/-/g, '_')
            typeVal = priceFilt5[1]
          }
        }
      }

      //console.log("areaVal", areaVal)

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {

        if(pcategorytype === "auction_commercial" || pcategorytype === "auction_residential"){
          areaVal = "UK"; // TODO
        } else if(pcategorytype === "commercial"){
          areaVal = "London";
        } else{
          areaVal = "London"; // TODO
        }
        
    }

    var areaAlias = areaVal.replace(' ', '-');

    //console.log("$$$$$$$---parse---", pcategorytype, pType, typeVal, minpriceVal, maxpriceVal)

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      pcategorytype:pcategorytype,
      pcategorytype2:pcategorytype2,
      type: typeVal,
      mustInclude: mustIncludeVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      size: {
        min: minsizeVal,
        max: maxsizeVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {
  const {areas, bedrooms, pType, type, price, size, mustInclude} = params;

  return {
    department: "residential",
    searchType: pType,
    areas: areas,
    bedroom: bedrooms,
    minPrice:  price.min,
    maxPrice:  price.max,
    minSize:  size.min,
    maxSize:  size.max,
    building: type,
    must_Include: mustInclude,
  }
}


// TODO: hard coded details to be removed/replaced with common variables
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const propertyH1 = (params, pcategorytype) => {
  // console.log('params : ' + params);

  const {areas, bedrooms, pType, type, price, size, extra, mustInclude} = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype+"_"+pType;

  let h1 = "";
  let building = "";

  var h1_type = type ? propertyTypesH1[type] : "";

  //console.log("pcategorytype", pType, type, pcategorytype, price, bedrooms, areas)

  if (pcategorytype === 'commercial') { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if (h1_type) {
      if(h1_type === "investment") {
        building = ` ${h1_type} `+"property ";
      } else {
        building = ` ${h1_type} `;
      }
    } else {
      building = `Commercial Property `;
    }

    h1 += toTitleCase(building);

    

    if (areas) {
      h1 += ACTION_TYPES[replace_url];

      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    } else{
      h1 += ACTION_DESC_TYPES[replace_url];
    }


    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( size && (size.min || size.max) )  {
      h1 += getSizeStr(size);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

  } else if('auction_commercial' === pcategorytype || 'auction_residential' === pcategorytype){
    building = `Auction Properties `;

    //console.log("areas", areas)

    h1 += toTitleCase(building);
    h1 += ACTION_TYPES[replace_url];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

  } else {

    let building = "Property";
    if (bedrooms)
      building = "Property";

    if (h1_type) {
      building = ` ${h1_type} `;
    } else {
      building = ` Property `;
    }

    h1 += toTitleCase(building);
    h1 += ACTION_TYPES[replace_url];

    

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road")
    }

    if(bedrooms === 0){
      //h1 += "Studio ";
      h1 += " with Studio";
    }

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+' : '+';
      h1 += " with more than "+ bedrooms +" bedrooms ";
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

  }

  // console.log("H1 title => ", h1);
  return h1;
}

export const propertyH1Intro = (params, pcategorytype) => {
  // console.log('params : ' + params);

  const {areas, bedrooms, pType, type, price, size, extra, mustInclude} = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype+"_"+pType;

  let h1 = "";
  let building = "";

  var h1_type = type ? propertyTypesH1[type] : "";

  //console.log("pcategorytype", pType, type, pcategorytype, price, bedrooms, areas)

  if (pcategorytype === 'commercial') { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if (h1_type) {
      if(h1_type === "investment") {
        building = ` ${h1_type} `+"property ";
      } else {
        building = ` ${h1_type} `;
      }
    } else {
      building = `Commercial Property `;
    }

    h1 += building.toLowerCase();

    

    if (areas) {
      h1 += ACTION_TYPES[replace_url];

      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road");
    } else{
      h1 += ACTION_DESC_TYPES[replace_url];
    }


    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( size && (size.min || size.max) )  {
      h1 += getSizeStr(size);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

  } else if('auction_commercial' === pcategorytype || 'auction_residential' === pcategorytype){
    building = `Auction Properties `;

    //console.log("areas", areas)

    h1 += building.toLowerCase();
    h1 += ACTION_TYPES[replace_url];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road");
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

  } else {

    let building = "Property";
    if (bedrooms)
      building = "Property";

    if (h1_type) {
      building = ` ${h1_type} `;
    } else {
      building = ` Property `;
    }

    h1 += building.toLowerCase();
    h1 += ACTION_TYPES[replace_url];

    

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north","North").replace("road","Road");
    }

    if(bedrooms === 0){
      //h1 += "Studio ";
      h1 += " with Studio";
    }

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+' : '+';
      h1 += " with more than "+ bedrooms +" bedrooms ";
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

  }

  // console.log("H1 title => ", h1);
  return h1;
}